header {
  position: fixed;
  top: 0;
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 100;
  padding: 20px;

  @media (max-width: 1140px) {
    padding: 0 20px;
  }

  @media (max-width: 980px) {
    height: 70px;
  }

  .header {
    max-width: 1300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;

    @media (max-width: 1140px) {
      gap: 40px;
    }

    &__navigation {
      display: flex;
      height: 32px;
      align-items: center;
      justify-content: space-between;
      gap: 32px;

      @media (max-width: 1140px) {
        display: flex;
        justify-content: space-between;
        gap: 20px;
      }

      @media (max-width: 1242px) {
        display: none;
      }

      &-item {
        color: #1D1E24;
        font-family: 'Inter-600';
        font-size: 16px;
        line-height: 22.40px;
        word-wrap: break-word;
        text-decoration: none;
        border-bottom: 2.5px solid transparent;

        &:hover {
          color: #0086EE !important;
          border-bottom: 2.5px solid #0086EE !important;
          text-decoration: none !important;;
        }

        &:active {
          color: #0068B9;
          border-bottom: 2.5px solid #0068B9;
        }
      }

      .active {
        color: #0086EE;
        border-bottom: 2.5px solid #0086EE;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 32px;

      @media (max-width: 1242px) {
        display: none;
      }

      .button {
        font-family: 'Poppins-600';
        font-size: 16px;
        line-height: 24px;
        word-wrap: break-word;
        text-decoration: none;
        border-radius: 10px;
        height: 50px;
        padding: 14px 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
      }

      &-login {
        color: #0086EE;
        background: transparent;
        border: none;
        padding: 0 !important;

        &:hover {
          color: #007DDE;
          text-decoration: none !important;
        }

        &:active {
          color: #0068B9;
          text-decoration: none !important;
        }
      }

      &-demo {
        color: #fff;
        background: #0086EE;
        border: none;
        line-height: 150%;

        &:hover {
          background: #007DDE;
          text-decoration: none !important;
        }

        &:active {
          background: #0068B9;
          text-decoration: none !important;
        }
      }
    }

    &__navigation-mobile {
      display: none;

      @media (max-width: 1242px) {
        display: flex;
      }
    }
  }

  a {
    text-decoration: none !important;
  }
  a:hover {
    text-decoration: none !important;
  }
}


.header-mobile {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: #F7F9FC;
  z-index: 1000;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  gap: 36px;

  @media(max-width: 576px) {
      padding: 20px 20px 100px 20px;
      overflow-y: auto;
      text-align: center;
  }

  &__navigation {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      width: 100%;

      @media(max-width: 576px) {
          gap: 12px;
      }

      &-item {
          color: #1D1E24;
          font-family: 'Inter-600';
          font-size: 24px;
          line-height: 40px;
          word-wrap: break-word;
          text-decoration: none !important;
          padding: 10px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 3px solid transparent;

          @media(max-width: 576px) {
              font-size: 16px;
              line-height: 32px;
          }

          &:hover {
              border: 3px solid #0086EE;
              border-radius: 8px;
              color: #0068B9;
          }

          &-active {
              color: #0068B9;

              &:after {
                  content: '';
                  display: block;
                  width: 100%;
                  height: 2px;
                  background: #0068B9;
                  margin-top: 2px;
              }

          }
      }
  }

  &__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 20px;

      .button {
          font-family: 'Poppins-600';
          font-size: 24px;
          line-height: 40px;
          word-wrap: break-word;
          text-decoration: none !important;
          border-radius: 10px;
          height: 70px;
          padding: 14px 24px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;

          @media(max-width: 576px) {
              font-size: 16px;
              line-height: 32px;
              height: 50px;
          }
      }

      &-login {
          color: #0086EE;
          background: white;
          border: 1px #0086EE solid;

          &:hover {
              color: #007DDE;
              border: 1px solid #007DDE;
          }

          &:active {
              color: #0068B9;
              border: 1px solid #0068B9;
          }
      }

      &-demo {
          color: #fff;
          background: #0086EE;
          border: none !important;
          line-height: 150%;

          &:hover {
              background: #007DDE;
          }

          &:active {
              background: #0068B9;
          }
      }
  }

  a {
    text-decoration: none !important;
      width: 100%;
  }
}

.overlay {
  display: none;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: fixed;
  width: 100%;
  text-align: center;
  background-color: $lightgray;
  height: 100%;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
  background-image: unset !important;
  background-size: unset !important;
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;
}

.overlay a:hover,
.overlay a:focus {
  opacity: 0.7;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

.site-header {
  display: none !important;
}


@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px
  }

  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}


.header-img {
  max-width: none !important;
  margin-bottom: 4px;
}