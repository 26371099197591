// start content
@charset "utf-8";

$grey-color:       #828282 !default;
$link-color: #1082e7;

@import 'fonts';
@import 'bootstrap/bootstrap';
@import 'footer';
@import 'header';


/* add additional CSS rules below */
html {
    scroll-behavior: smooth;
  }

body {
    position: relative;
    font-size:17px;
    font-family: 'Source Sans Pro';
    line-height:1.7;
    color: #333;
    overflow-x:hidden;
}

.source-sans-font {
    font-family:'Source Sans Pro';
}
@media (max-width: 1366px) {
    .container-xl, .container-lg, .container-md, .container-sm, .container {
        width:90%;
        max-width:90%;
        padding:0 25px;
    }
}
main {min-height:500px;}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, .navbar-light .navbar-brand, .text-dark, .dropdown-item {
    color: #000;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight:600;
}
h4, .h4, h5, .h5 {
    line-height:1.4;
}

.font-weight-bold {
    font-weight:600 !important;
}
a {
    color:inherit;
    text-decoration:none;
    &:hover {
        text-decoration:none;
        color:inherit;
    }
}

img, video {
    max-width: 80%;
}

.item-card .categories {
    position: absolute;
    top: 10px;
    left: 30px;
    font-size:13px;
    a {
        background:#333;
        color:white;
        padding:2px 4px;
        border-radius:4px;
    }
}
small, .small {
    font-size: 90%;
}
.big {
    font-size:1.4em;
}


// hero page
@keyframes wind{
    0% {
      background-position: 0 200px, 0 300px, 100px 250px;
    }
    100% {
      background-position: 1000px 200px, 1200px 300px, 1100px 250px;
    }

  }

.hero {
    position: relative;
    width: 100vw;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
 }
 .hero-logo {
   width: 660px;
}
 .hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    // background: url(/assets/images/welcome-bg-no-clouds.svg);
    // background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: 100%;
 }
 #cloud-intro{
    height: 500px;
    background: url(/assets/images/clouds.png) 0 100px,
    url(/assets/images/clouds-blur.png) 0px 250px;
    animation: wind 60s linear infinite alternate;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
 .hero-content {
    position: relative;
    text-align: center;
    margin: 0.625rem;
 }
@media (max-width: 450px) {
    .hero {
        height: 250px;
    }

    .hero::before {
        height: 250px;
    }
}

@media only screen and (max-width: 1024px) {
    .hero-logo {
        max-width: 90%;
     }
}
@media only screen and (max-width: 767px) {
    .page-header.big {
        font-size:2.5em;
    }
    .hero {
        height: 350px;
    }
    .hero::before {
        height: 350px;
     }

}
.google-auto-placed {
    margin-bottom:2rem;
}
.comma:last-child {
    display:none;
}

.hero h1 {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 63px;
    color: #212529;
    text-align: center;
}
@media (max-width: $mobile) {
     .hero {
        h1 {
          font-size: 25px;
        }
    }
    .hero::before {
        background: unset;
    }
  }
.section-title h2 {
	font-weight:700;
	font-size:1.4rem;
	margin-bottom:27px;
    position: relative;

}
.section-title h2:before {
    margin-bottom: 0;
    position: absolute;
    z-index: 1;
    left: 15px;
    right: 0px;
    top: 15px;
    height: 1px;
    content: '';
    background-color: #efefef;
}

.section-title span {
    background-color: #fff;
    position: relative;
    z-index: 2;
    display: inline-block;
    padding: 0px 15px 0 0;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.layout-page  .section-title span {
    font-size: 2rem;
    text-transform: none;
    letter-spacing:0;
}
.layout-page .section-title h2:before {top:17px;}
.article-post ol, .article-post ul {  margin-bottom:1.5rem; margin-left: 1.5rem;}
.article-post ol ol, .article-post ul ul {
    list-style: disc;
    margin-bottom:0rem;
     margin-left: 1.5rem;
}
.prevnextlinks .thepostlink {
    padding: 20px 0;
    display: block;
    color:  $link-color;
    font-weight: 600;
    font-size: 16px;
}
.prevnextlinks {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin-left: 0;
    margin-right: 0;
}
.prevnextlinks .rightborder {
    border-right: 1px solid #eee;
}
.author-thumb {
    object-position: center;
    width: 50px;
    height: 50px;
    margin-right: 9px;
    border-radius: 100%;
    border: 2px solid #eaeaea;
    padding: 2px;
    object-fit: cover;
}
.post-top-meta {
	margin-bottom: 1rem;
    margin-top: 3rem;
}
.post-top-meta .author-thumb {
	width:72px;
	height:72px;
}
.post-top-meta.authorpage .author-thumb {
	margin-top:40px;
}
.post-top-meta span {
	font-size:0.9rem;
	color:rgba(0,0,0,.44);
	display:inline-block;
}
.post-top-meta .author-description {
	margin-bottom:5px;
	margin-top:10px;
	font-size:0.95rem;
}
.author-meta {
	flex:1 1 auto;
	white-space:nowrap!important;
	text-overflow:ellipsis!important;
	overflow:hidden!important;
    font-style: italic;
}
span.post-name,span.post-date,span.author-meta {
	display:inline-block;
}

span.post-read-more a {
	color:rgba(0,0,0,.44);
     align-items: center;
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #d0d0d0;
    border-radius: 50%;
}
span.post-name a,span.post-read-more a:hover {
	color:rgba(0,0,0,.8);
}
.dot:after {
	content:"·";
	margin-left:3px;
	margin-right:3px;
}
.mainheading h1.posttitle {
	font-weight:700;
	margin-bottom:2rem;
}
.link-dark {
	color:rgba(0,0,0,.8);
}
.article-post>*{
    margin-bottom:1.5rem;
}
.article-post {
    font-size: 1.18rem;
    line-height: 1.64;
	color:rgba(0,0,0,.8);
    code {
        font-size:15px;
    }
    a {
        color: $link-color;
        font-weight: 600;
        font-size: 18px !important;
    }
    img, iframe {
        border-radius: 0.25rem !important;
    }
}

blockquote {
	border-left:4px solid #222;
	padding:0 0 0 20px;
	font-style:italic;
	color:rgba(0,0,0,.5);
    margin-left:30px;
    font-size: .9em;
}
.featured-image {
    display: block;
    margin-bottom: 2rem;
}

.full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

@media (max-width:1024px) {
	.post-top-meta .col-md-10 {
		text-align:center;
	}
}
@media (max-width:767px) {
	.post-top-meta.authorpage {
		text-align:center;
	}
}


.card {
    border:0;
}
.card .img-thumb {
	border-top-right-radius:4px;
	border-top-left-radius:4px;
}
ul.tags {
	list-style:none;
	padding-left:0;
	margin: 0 0 1.5rem 0;
}
ul.tags li {
	display:inline-block;
	font-size:0.9rem;
}
ul.tags li a {
    color: #333;
    padding: 3px 10px;
    border-radius: 3px;
    font-weight: 500;
    background:#eee;
    text-transform: capitalize;
    box-shadow: 0 6px 10px 0 rgba(169, 169, 169, 0.1);
}
ul.tags li a:hover {
	background:rgba(0,0,0,.07);
	text-decoration:none;
}
.margtop3rem {
	margin-top: 3rem;
}
.sep {
	height:1px;
	width:20px;
	background:#999;
	margin:0px auto;
	margin-bottom:1.2rem;
}


.btn.follow {
	border-color:#ccc;
	color:#999;
	padding:3px 10px;
	text-align:center;
	border-radius:999em;
	font-size:0.85rem;
	display:inline-block;
        box-shadow: none;
    font-weight:400;
}
.btn.subscribe {
	background-color:#1C9963;
	border-color:#1C9963;
	color:rgba(255,255,255,1);
	fill:rgba(255,255,255,1);
	border-radius:30px;
	font-size:0.85rem;
	margin-left:10px;
	font-weight:600;
	text-transform:uppercase;
}
.post-top-meta .btn.follow {
	margin-left:5px;
	margin-top:-4px;
}

.form-control::-webkit-input-placeholder {
	color: rgba(0,0,0,.5);
}
.form-control:-moz-placeholder {
	color: rgba(0,0,0,.5);
}
.form-control::-moz-placeholder {
	color: rgba(0,0,0,.5);
}
.form-control:-ms-input-placeholder {
	color: rgba(0,0,0,.5);
}
.form-control::-ms-input-placeholder {
	color: rgba(0,0,0,.5);
}
.form-control {border:1px solid #eee; border-radius:0;}
.authorpage h1 {
	font-weight:700;
	font-size:30px;
}
.post-top-meta.authorpage .author-thumb {
	float:none;
}
.authorpage .author-description {
	font-size:1rem;
	color:rgba(0,0,0,.6);
}
.post-top-meta.authorpage .btn.follow {
	padding:7px 20px;
	margin-top:10px;
	margin-left:0;
	font-size:0.9rem;
}
.graybg.authorpage {
	border-top:1px solid #f0f0f0;
}
.authorpostbox {
	width:760px;
	margin:0px auto;
	margin-bottom:1.5rem;
	max-width:100%;
}
.authorpostbox .img-thumb {
	width:100%;
}
.sociallinks {
	margin:1rem 0;
}
.sociallinks a {
	background:#666;
	color:#fff;
	width:22px;
	height:22px;
	display:inline-block;
	text-align:center;
	line-height:22px;
	border-radius:50%;
	font-size:12px;
}
#comments {
    margin-top: 3rem;
    margin-bottom: 1.5rem;
}



.article-post .h1, .article-post .h2, .article-post .h3, .article-post .h4, .article-post .h5, .article-post .h6, .article-post h1, .article-post h2, .article-post h3, .article-post h4, .article-post h5, .article-post h6 {
    font-weight: 600;
    font-family: 'Source Sans Pro';
    margin-top: 3rem;
}

img {
    max-width: 80%;
    height: auto;
}

.bottompagination span.navigation a {
    padding: 5px;
    border-radius: 3px;
    text-transform: none;
    font-weight: 600;
    min-width: 50px;
    display: inline-block;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: $link-color;
}
.pointerup {
	margin-bottom:-18px;
	margin-left:49%;
	font-size:30px;
}
.pointerup i.fa {
	color:#eaeaea;
}
.bottompagination span.navigation i {
	display:inline-block;
}
.bottompagination {
    padding: 30px;
    width: 100%;
}
span.navigation {
    display: block;
    font-size: 0.93rem;
    font-weight: 700;
    text-align:center;
    position:relative;
}
span.navigation:before {
    margin-bottom: 0;
    position: absolute;
    z-index: 1;
    left: 0px;
    right: 0px;
    top: 12px;
    height: 1px;
    content: '';
}
.pagination {
    display: inline-block;
    background: transparent;
    z-index: 1;
    position: relative;
    padding: 0 20px;
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 500;
    text-transform: uppercase;
}

.selected-page{
    padding: 5px;
    border-radius: 3px;
    text-transform: none;
    font-weight: 600;
    min-width: 50px;
    display: inline-block;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
}

.hover-opacity:hover {
    transition: all.3s;
    opacity:.8;
}

.contacts__social {
    .icons {
        list-style-type: none;
        padding: 0px;
    }
}

.site-container{
    position: relative;
}

.site-container::after {
    content: '';
    // background: url(/assets/images/cloud-footer-bg.jpg) no-repeat bottom;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-size: contain;
    opacity: 0.55;

}

.site-container {
    .container {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
      }
      @media (min-width: 768px) {
        .container {
          width: 750px;
        }
      }
      @media (min-width: 992px) {
        .container {
          width: 970px;
        }
      }
      @media (min-width: 1200px) {
        .container {
          width: 1170px;
        }
      }
}

.pagination-preview-disabled {
    padding: 5px;
    border-radius: 3px;
    text-transform: none;
    font-weight: 600;
    min-width: 50px;
    display: inline-block;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
}

/* Share buttons */
.sharebuttons {
    margin: 0 auto 0 auto;
  }

  .sharebuttons p {
    color: $grey-color;
    text-align: center;
  }

  .sharebuttons ul {
    margin: 100px 0 0 0;
    text-align: center;
  }

  .sharebuttons ul li {
    display: inline;
  }

  .sharebuttons ul li a {
    text-decoration: none !important;
  }
  .sharebuttons ul li a:hover {
    text-decoration: none !important;
  }

  .sharebuttons ul li svg {
    width: 40px;
    height: 40px;
  }

  .sharebuttons .reddit svg {
    fill: #FF4500;
  }

  .sharebuttons .hn svg {
    fill: #F0652F;
  }

  .sharebuttons .twitter svg {
    fill: #1DA1F2;
  }

  .sharebuttons .linkedin svg {
    fill: #0077B5;
  }

  .marginRight10{
    margin-right:10px;
  }
  .cc-allow:hover {
    color: #000 !important;
  }

  .login-btn{
    display: flex;
    align-items: center;
    padding: 13px 30px;
    gap: 10px;
    width: 99.1px;
    height: 40px;
    top: 20px;
    background: linear-gradient(114.86deg, #005AEC -38.18%, #12DFE7 110.93%);
    border-radius: 10px;
  }


.navbar-site-logo {
    padding: 0px;
    margin-top: -10px;
}


.blog-heading {
    border-bottom: 3px solid $link-color;
}


.blog-tile-card {
    .card-title, .card-text {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}
.article-post img {
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
    display: block;
}

.shadow {
    filter: drop-shadow(0 0 20px#b3b3b3);
  }

.navbar-nav li>a {
    position: relative;
    color: $link-color;
    font-weight: 600;
}

a:hover {
    color: #111 !important;
    text-decoration: underline !important;
}

.post-card-link:hover {
    color: inherit !important;
    text-decoration: none !important;
}

.gradient-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px 30px;
    background: $gradient;
    color: $white;
    border-radius: 10px;
    cursor: pointer;
    border: 0px;

    &:hover {
        opacity: 0.95 !important;
    }
}

.menu {
    margin: unset !important;
}

.header-container {
    a {
        text-decoration: none !important;
        background-image: none !important;
        background-repeat: unset !important;
        background-position: unset !important;
        background-size: unset !important;
        color: #06053B;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        text-decoration: none;
        letter-spacing: 0.05em;
    }
}

div.video {
    iframe {
        display: block;
        margin: 3rem auto;
        width: 75%;
        aspect-ratio: 16 / 9;
    }
}
#tldr, #tldr-1 {
    display: none;
}