$gradient: linear-gradient(114.86deg, #005AEC -38.18%, #12DFE7 110.93%);
$hover-gradient: linear-gradient(276.23deg, #005AEC 6.06%, #12DFE7 126.61%);
$shadow: 0px 5px 10px rgba(0, 19, 36, 0.05);


// colors

$title: #06053B;
$white: #ffffff;
$lightblue: #0CADEA;
$owasp: #6961AA;
$lightgray: #F9F9F9;
$graysecure: #F3F9FC;
$blue: #035EE8;
$black: #212529;
$gray: #A99494;
$border: #B4D9E4;
$darkblue: #011C3A;
// other

$tablet-landscape: 1140px;
$tablet: 900px;
$mobile: 600px;


@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
@font-face {
  font-family: 'Inter-600';
  src: url('./fonts/Inter-SemiBold.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-400';
  src: url('./fonts/Inter-Regular.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Poppins-600';
  src: url('./fonts/Poppins-SemiBold.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Poppins:wght@400;500;700&display=swap');

* {
  font-family: "Source Sans Pro", sans-serif;
  color: $title;
}

a {
  color: $title;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  text-decoration: none;

  &:checked {
    color: unset;
  }

  &:hover {
    opacity: 0.7;
  }
}

button {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}

h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 82px;
  color: $title;
}

h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 48px;
}
.text-gradient {
  background: $gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.color-blue {
  color: $blue;
}
h3,
.h3 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

h4 {
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

p,
div {
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 42px;
  color: $lightblue;
}

.subtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: $black;
}

.text-blue {
  color: $lightblue;
  font-style: italic;
}

@media (max-width: $tablet) {
  h1,
  .h1 {
    font-size: 36px;
    line-height: 48px;
    font-weight: 700;
  }
  h2 {
    font-size: 28px;
    line-height: 34px;
  }
  h3,
  .h3 {
    font-size: 22px;
    line-height: 28px;
    font-weight: 600;
    &.text {
      font-weight: normal;
    }
  }
  h4 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }
  .title {
    font-size: 24px;
    font-weight: 32px;
  }
  .title-light {
    font-size: 16px;
    font-weight: 20px;
  }
}

#CookieConsent {
  font-size: 15px;
  font-family: 'Poppins-600';
  position: fixed;
  z-index: 999;
  left: 5%;
  transform: translateX(-50%);
  transition: bottom 0.5s ease;
  text-align: center;
  color: #fff;
  background: #0086EE;
  border: none;
  padding: 10px;
  border-radius: 8px;
  bottom: -36px;
}

#CookieConsent:hover {
  background: #007DDE;
}

#CookieConsent:active {
  background: #0068B9;
}
