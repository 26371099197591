// Footer css
footer {
    background-color: #1D1E24;
    width: 100%;
    height: 146px;
    padding: 120px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 103;
  
    @media (max-width: 1140px) {
      padding: 120px 20px;
    }
  
    @media (max-width: 1140px) {
      padding: 40px 20px;
      height: auto;
    }
  
    .footer {
      max-width: 1300px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
  
      &__divider {
        height: 1px;
        align-self: stretch;
        border-radius: 60px;
        opacity: 0.5;
        background: #BBBCCA;
        margin: 48px 0;
      }
  
      &__links {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
  
  
        &-sitemap {
          display: flex;
          align-items: center;
          gap: 32px;
          flex: 1 0 0;
  
          @media (max-width: 980px) {
            flex-wrap: wrap;
            justify-content: center;
          }
  
          &-item {
            font-family: 'Poppins-600';
            font-size: 16px;
            font-style: normal;
            line-height: 150%;
            text-decoration: none !important;
            color: #FFFFFF !important;
  
            @media (max-width: 980px) {
              width: 40%;
            }
  
            &:hover {
              color: #0086EE !important;
              text-decoration: none !important;
            }
  
            &:active {
              color: #0068B9 !important;
              text-decoration: none !important;
            }
          }
  
          &-logo {
            @media (max-width: 980px) {
              width: 100%;
            }
          }
  
          .policy-mobile {
            display: none;
  
            @media (max-width: 980px) {
              display: flex;
            }
          }
        }
  
        &-social {
          display: flex;
          align-items: center;
          gap: 48px;
  
          a {
            cursor: pointer;
          }
  
          @media (max-width: 1140px) {
            gap: 30px;
          }
  
          @media (max-width: 980px) {
            display: none;
          }
        }
      }
  
      &__copyright {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
  
        @media (max-width: 980px) {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 24px;
        }
  
        &-policy {
          font-size: 16px;
          font-style: normal;
          line-height: 150%;
          font-family: 'Poppins-600';
          text-decoration: none !important;
          color: #FFFFFF !important;
  
          &:hover {
            color: #0086EE !important;
            text-decoration: none !important;
          }
  
          &:active {
            color: #0068B9 !important;
            text-decoration: none !important;
          }
  
          @media (max-width: 980px) {
            display: none;
          }
        }
  
        &-date {
          color: #8D949D;
          font-family: Inter-400;
          font-size: 14px;
          line-height: 150%;
          text-align: center;
        }
  
        .mobile {
          display: none;
  
          @media(max-width:980px) {
            display: flex;
          }
        }
      }
    }
  }

  .footer-img{
    max-width: none !important;
    width: 150px;
    height: 40px;
    margin-bottom: 5px;
  }